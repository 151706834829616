<template>
  <!-- 在线选座管理 -->
  <div class="onLineSeatSelection-page">
    <div ref="tabs" class="tabs">
      <el-tabs
        v-model="channelNo"
        type="card"
        @tab-click="getQuerySeatPlanPage"
      >
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <el-button
      type="primary"
      size="small"
      class="add"
      @click="openDialog('新增座位布局')"
      >新建</el-button
    >
    <Table
      :col-class-type="true"
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
    >
      <template slot-scope="scope">
        <div>
          <template>
            <el-button
              type="text"
              size="small"
              sort="primary"
              @click="openDialog('编辑座位布局', scope.scopeRow)"
              >编辑</el-button
            >
          </template>
          <template>
            <el-button
              type="text"
              size="small"
              sort="primary"
              @click="deleteFun(scope.scopeRow)"
              >删除</el-button
            >
          </template>
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <seatSelectionDialog
      :channelNo="channelNo"
      ref="seatSelectionDialogRef"
    ></seatSelectionDialog>
  </div>
</template>

<script>
import {
  querySeatPlanPageAPI,
  updateSeatPlanAPI,
  deleteSeatPlanByIdAPI,
  getCurrentUserChannelInfoAPI,
} from "@/api/lib/api.js";
import seatSelectionDialog from "./seatSelectionDialog.vue";
export default {
  name: "",
  components: { seatSelectionDialog },
  props: {},
  data() {
    return {
      tableData: [],
      titleName: [
        {
          title: "座位布局名称",
          props: "seatName",
        },
        {
          title: "座位数",
          props: "seatNumber",
        },
        {
          title: "票位",
          props: "ticketNumber",
        },
        {
          title: "座位排数",
          props: "seatRow",
        },
        {
          title: "每排最大座位数",
          props: "seatCol",
        },
        {
          title: "启用",
          props: "status",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status,
                activeValue: 0,
                inactiveValue: 1,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  let obj = {
                    status: val,
                    id: params.id,
                    seatName: params.seatName,
                  };
                  updateSeatPlanAPI(obj).then((res) => {
                    if (res.code == "SUCCESS") {
                      this.getQuerySeatPlanPage();
                    }
                  });
                },
              },
            });
          },
        },
      ],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      channelList: [],
      channelNo: "",
    };
  },
  filters: {},
  computed: {},
  watch: {},
  created() {
    this.getQueryChannelList();
  },
  mounted() {},
  destroyed() {},
  methods: {
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
          this.channelNo = res.data[0]?.channelNo;
          this.getQuerySeatPlanPage();
        }
      });
    },
    //座位规划模板分页查询
    getQuerySeatPlanPage() {
      let params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        channelNo: this.channelNo,
      };
      querySeatPlanPageAPI(params).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.currentPage = page;
      this.getQuerySeatPlanPage();
    },
    //操作座位布局弹窗
    openDialog(title, row) {
      this.$refs.seatSelectionDialogRef.openDialog(title, row?.id);
    },
    //删除座位规划模板
    deleteFun(row) {
      this.$confirm("是否删除该座位布局？", "是否删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteSeatPlanByIdAPI({ id: row.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getQuerySeatPlanPage();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.onLineSeatSelection-page {
  .add {
    margin-bottom: 20px;
  }
}
</style>
