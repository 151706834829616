var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "onLineSeatSelection-page" },
    [
      _c(
        "div",
        { ref: "tabs", staticClass: "tabs" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.getQuerySeatPlanPage },
              model: {
                value: _vm.channelNo,
                callback: function ($$v) {
                  _vm.channelNo = $$v
                },
                expression: "channelNo",
              },
            },
            _vm._l(_vm.channelList, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.channelName, name: item.channelNo },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "add",
          attrs: { type: "primary", size: "small" },
          on: {
            click: function ($event) {
              return _vm.openDialog("新增座位布局")
            },
          },
        },
        [_vm._v("新建")]
      ),
      _c("Table", {
        attrs: {
          "col-class-type": true,
          "table-data": _vm.tableData,
          "title-name": _vm.titleName,
          operation: true,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  [
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            size: "small",
                            sort: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openDialog(
                                "编辑座位布局",
                                scope.scopeRow
                              )
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                    ],
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            size: "small",
                            sort: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteFun(scope.scopeRow)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.pageSize,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.onCurrentChange },
          }),
        ],
        1
      ),
      _c("seatSelectionDialog", {
        ref: "seatSelectionDialogRef",
        attrs: { channelNo: _vm.channelNo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }